<template>
  <div class="shipping-address-detail" :class="{skeleton:!state.loaded}">
    <div class="wrapper">
      <div class="title font-md">
        <b>배송지 {{ state.form.addressSeq ? "수정" : "추가" }} </b>
      </div>
      <div class="desc font-sm">배송 정보를 수정하신 후 저장하기 버튼을 눌러주세요.</div>
      <div class="content">
        <ul class="tight font-sm">
          <div class="wrapper">
            <div class="form-group">
              <label class="font-sm" :for="`${component.name}AddressName`">배송지</label>
              <select :id="`${component.name}AddressName`" class="font-sm border-focus-point form-control" v-model="state.form.addressName" @change="onAddressSelectChange()">
                <option :value="a" v-for="(a, idx) in state.address.list" :key="idx">{{ a }}</option>
              </select>
              <input :id="`${component.name}AddressNameInput`" type="text" class="form-control border-focus-point font-sm mt-1" placeholder="ex) 학교" v-model="state.address.input"
                     maxlength="15" v-if="state.address.list.indexOf(state.form.addressName) === state.address.list.length - 1"/>
            </div>
            <div class="form-group">
              <label class="font-sm" :for="`${component.name}Name`">이름</label>
              <input type="text" class="form-control border-focus-point font-sm" :id="`${component.name}Name`" placeholder="ex) 오마이" v-model="state.form.name"/>
            </div>
            <div class="form-group">
              <label class="font-sm" :for="`${component.name}Mobile`">휴대전화</label>
              <Phone :id="`${component.name}Mobile`" :value.sync="state.form.mobile" placeholder="ex) 01012345678"/>
            </div>
            <div class="form-group">
              <label class="font-sm" :for="`${component.name}Address`">주소</label>
              <Address componentNameSuffix="InShippingAddressDetailModal" :id="`${component.name}Address`" :zoneCode.sync="state.form.post" :address1.sync="state.form.addr1" :address2.sync="state.form.addr2"/>
            </div>
            <div class="form-check mt-2">
              <input class="form-check-input" type="checkbox" :id="`${component.name}ChkDefaultAddress`" v-model="state.checkDefaultAddress"/>
              <label class="form-check-label" :for="`${component.name}ChkDefaultAddress`">기본 배송지로 등록</label>
            </div>
          </div>
        </ul>
      </div>
    </div>
    <div class="action">
      <button class="btn btn-point btn-block font-sm" @click="submit()">저장하기</button>
    </div>
  </div>
</template>

<script>
import {defineComponent, nextTick, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import store from "@/scripts/store";
import Address from "@/components/Address";
import Number from "@/components/Number";
import Phone from "@/components/Phone";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import lib from "@/scripts/lib";

function Component(initialize) {
  this.name = "modalShippingAddressDetail";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Phone, Address, Number},
  setup() {
    const component = new Component(() => {
      if (modalParams.address !== undefined) {
        state.form.addressSeq = modalParams.address.addressSeq;
        state.form.addressName = modalParams.address.addressName;
        state.form.name = modalParams.address.name;
        state.form.mobile = modalParams.address.mobile;
        state.form.post = modalParams.address.post;
        state.form.addr1 = modalParams.address.addr1;
        state.form.addr2 = modalParams.address.addr2;
        state.checkDefaultAddress = modalParams.address.defaultFlag === "Y";

        if (!state.address.list.includes(state.form.addressName)) {
          state.address.input = state.form.addressName;
          state.form.addressName = state.address.list[state.address.list.length - 1];
        }
      } else {
        state.form.addressName = state.address.list[0];
      }

      store.commit("tuneModal", {component, size: "sm"});
    });

    const state = reactive({
      loaded: false,
      checkDefaultAddress: false,
      form: {
        addressSeq: "",
        addressName: "",
        name: "",
        mobile: "",
        post: "",
        addr1: "",
        addr2: "",
        defaultFlag: "",
      },
      address: {
        input: "",
        list: ["집", "회사", "가족", "친구", "직접 입력"],
      },
    });

    const modalParams = store.getters.modalParams(component);

    const submit = () => {
      const args = lib.getRenewed(state.form);

      const warn = (message, id) => {
        document.getElementById(id).focus();
        return store.commit("setSwingMessage", message);
      };

      if (state.address.list.indexOf(args.addressName) === state.address.list.length - 1 && !state.address.input.trim()) {
        return warn("배송지명을 입력해주세요.", `${component.name}AddressNameInput`);
      } else if (!args.name) {
        return warn("이름을 입력해주세요.", `${component.name}Name`);
      } else if (!args.mobile) {
        return warn("휴대전화 번호를 입력해주세요.", `${component.name}Mobile`);
      } else if (!lib.isValidPhoneNum(args.mobile)) {
        return warn("휴대전화 번호 형식이 올바르지 않습니다.", `${component.name}Mobile`);
      } else if (!args.addr1 || !args.addr2 || !args.post) {
        return warn("주소를 입력해주세요.", `${component.name}Address`);
      }

      if (state.address.list.indexOf(args.addressName) === state.address.list.length - 1) {
        args.addressName = state.address.input.trim();
      }

      args.defaultFlag = (state.checkDefaultAddress) ? "Y" : "N";

      http.post(`/api/member/${store.state.account.memberSeq}/address`, args).then(() => {
        store.commit("closeModal", {
          name: component.name,
          onClose(modal) {
            store.dispatch("callback", {modal});
          }
        });
      }).catch(httpError());
    };

    const onAddressSelectChange = () => {
      if (state.address.list.indexOf(state.form.addressName) === state.address.list.length - 1) {
        state.address.input = "";
        nextTick(() => {
          document.getElementById(`${component.name}AddressNameInput`).focus();
        });
      }
    };

    return {component, state, submit, onAddressSelectChange};
  },
});
</script>

<style lang="scss" scoped>
.shipping-address-detail {
  > .wrapper {
    background: #fff;
    padding: $px25;

    .title {
      margin-bottom: $px9;
    }

    .desc {
      margin-bottom: $px25;
      color: #666;
    }

    .content {
      .form-control {
        height: $formHeight;
      }
    }
  }

  .action {
    button {
      padding: $px15;
      height: $px56;
      border-radius: 0;

      span {
        vertical-align: middle;

        &.img {
          width: $px24;
          height: $px24;
          margin-left: $px8;
        }
      }
    }
  }

  &.skeleton {
  }

  @media(max-width: 767px) {
    > .wrapper .content > ul > li > .wrapper {
      padding-right: 0;

      > .btn {
        position: static;
        transform: none;
        margin-top: $px15;
      }
    }
  }
}
</style>